import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { panelTitle, h3 } from '../styles/typography';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import Row from './Row';
import BorderSeparator from './BorderSeparator';
import { withModal } from '../store/ModalProvider';
import SideModal from './SideModal';
import GridPeople from './GridPeople';
import * as Scroll from 'react-scroll';
import { useAppear } from '../hooks/useAppear';

const PanelWorkTeam = ({
    title,
    image,
    goalsRichText,
    descriptionRichText,
    doctors,
    nurses,
    addModal,
    removeModal,
    hasModal
}) => {
    // Filter data
    const filteredDoctors = doctors.filter(({ node }) => node !== null).map(({ node }) => node);
    const filteredNurses = nurses.filter(({ node }) => node !== null).map(({ node }) => node);

    // Hooks
    const refAppearLeft = useAppear();
    const refAppearRight = useAppear(0.2);

    // Callbacks
    const handleModalPoseComplete = uid => {
        if (hasModal(`modal-${uid}`) !== true) {
            document.documentElement.classList.remove('no-scroll');
        }
    };

    const handleOpenModal = (data, event) => {
        event.preventDefault();
        const { uid, title, biography } = data;
        const modal = (
            <SideModal
                key={`modal-${uid}`}
                handleClose={() => removeModal(`modal-${uid}`)}
                onPoseComplete={() => handleModalPoseComplete(uid)}
                title={title.text}
                html={biography.html}
            />
        );

        addModal(modal);
    };

    return (
        <Scroll.Element name="work-team">
            <StyledBackground>
                <StyledRow>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledImage fluid={image} />
                </StyledRow>
            </StyledBackground>
            <StyledRowText>
                <div ref={refAppearLeft} dangerouslySetInnerHTML={{ __html: goalsRichText }} />
                <div
                    ref={refAppearRight}
                    dangerouslySetInnerHTML={{ __html: descriptionRichText }}
                />
            </StyledRowText>
            <BorderSeparator />
            <StyledWrapper>
                <StyledRow>
                    <GridPeople
                        people={filteredDoctors}
                        onClickItem={handleOpenModal}
                        carouselOnMobile={true}
                    />
                </StyledRow>
                <BorderSeparator />
                <StyledRow>
                    <GridPeople people={filteredNurses} carouselOnMobile={true} />
                </StyledRow>
            </StyledWrapper>
        </Scroll.Element>
    );
};

export default withModal(PanelWorkTeam);

const StyledTitle = styled.h2`
    ${panelTitle}
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)} 0;

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}
`;

const StyledRow = styled(Row)`
    overflow: initial;
`;

const StyledBackground = styled.div`
    background: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.white};
`;

const StyledWrapper = styled.div`
    position: relative;
    overflow: hidden;
`;

const StyledImage = styled(GatsbyImage)`
    position: relative;
    width: ${sizeAsBase(48)};
    margin: 0 auto;
    bottom: -${props => props.theme.spacing.s60};

    ${media.large`
        width: ${sizeAsMax(48)};
    `}

    ${media.mobile`
        bottom: -${props => props.theme.spacing.s30};
        width: 100%;
    `}
`;

const StyledRowText = styled(Row)`
    padding: ${props => props.theme.spacing.s160} ${sizeAsBase(2)};
    display: flex;
    justify-content: space-between;

    & > div:first-child {
        ${h3}
        color: ${props => props.theme.colors.red};
        flex-basis: ${sizeAsBase(18)};
    }

    & > div:last-child {
        flex-basis: ${sizeAsBase(33)};
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};

        & > div:first-child {
            flex-basis: ${sizeAsMax(18)};
        }

        & > div:last-child {
            flex-basis: ${sizeAsMax(33)};
        }
    `}

    ${media.mobile`
        padding-top: ${props => props.theme.spacing.s70};
        padding-bottom: ${props => props.theme.spacing.s50};
        flex-direction: column;

        & > div:first-child {
            flex-basis: 100%;
            margin-bottom: ${props => props.theme.spacing.s40};
        }

        & > div:last-child {
            flex-basis: 100%;
        }
    `}
`;
